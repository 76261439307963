document.addEventListener("DOMContentLoaded", function (event) {
    (function () {
      $('.mobile-slide-button').on('click', function(e) {
        resetMobileMenu();
        var this_is = $(this);
        var parent = this_is.closest('.mobile-slide-container');
        parent.find('.mobile-slide-body').addClass('show');
      });
      $('.mobile-slide-close, .offcanvas-backdrop').on('click', function(e) {
        resetMobileMenu();
      });
      var myOffcanvas = document.getElementById('offcanvasNavbar')
      myOffcanvas.addEventListener('hidden.bs.offcanvas', function () {
        resetMobileMenu();
      });
      function resetMobileMenu() {
        $('.mobile-slide-container').find('.mobile-slide-body').removeClass('show');
      }
    })();
});